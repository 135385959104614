export const LARGE_PRODUCT_ENTITY_INFO_LOCATION_FRAGMENT = `
  fragment largeProductEntityInfoLocationFragment on LargeProduct {
    uid
    name
    booths: _boothsOfEdition(editionSchemaCode: "%editionSchemaCode%", first: 1) {
      id
      number
      geozone {
        id
      }
      exhibitHall {
        id
        name
      }
    }
  }
`;
